import { useEffect, useState } from "react";
import ArtPlayer1 from '../ArtPlayer1';  // Adjust import path as needed
import '../Stream.css';  // Adjust import path as needed

function MovStream({ mediaId, imdbId }) {
    const [video, setVideo] = useState("");
    const [fetchVideo, setFetchVideo] = useState([]);
    const [qualityIndex, setQualityIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [subtitles, setSubtitles] = useState([]);
    const [firstSubtitleUrl, setFirstSubtitleUrl] = useState("");

    useEffect(() => {
        // Fetch video details from the video API
        if (!imdbId) {
            console.warn("IMDB ID is empty, skipping subtitle fetch.");
            return;
        }

        // Fetch subtitles from your Cloudflare Worker
        const subtitleApiUrl = `https://subtitle.ritsukamado.workers.dev/?imdb_id=${imdbId}.txt`;
                
        fetch(subtitleApiUrl)
            .then((response) => response.json())  // Subtitles are served as JSON
            .then((subtitleData) => {
                console.log(subtitleApiUrl);
                console.log("Fetched subtitles:", subtitleData); // Log subtitle data
                setSubtitles(subtitleData); // Set the subtitle data
                const englishSubtitles = subtitleData.filter(subtitle => 
                    subtitle.label.includes('English')
                );

                if (englishSubtitles.length > 0) {
                    setSubtitles(englishSubtitles); // Set only English subtitles
                    setFirstSubtitleUrl(englishSubtitles[0]?.file || "");  // Ensure proper default value
                } else {
                    console.warn("No English subtitles found.");
                }
            })
            .catch((error) => {
                console.error("Error fetching subtitles", error);
            });

        const apiUrl = `https://vidstream.ritsukamado.workers.dev?movieId=${mediaId}`;

        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                // Fetch video sources
                const fetchedVideo = data.MOVIE_TEST.sources;
                if (fetchedVideo && fetchedVideo.length > 0) {
                    setFetchVideo(fetchedVideo);
                    const resultsarray = fetchedVideo[qualityIndex]?.file.replace(/^file:/, "") || "";
                    setVideo(resultsarray);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching video data", error);
                setLoading(false);
            });

    }, [mediaId, imdbId, qualityIndex]);

    return (
        <div className='vidstream'>
            {video ? (
                <div>
                    <ArtPlayer1
                        className="artplayer"
                        option={{
                            url: video,
                            isLive: false,
                            muted: false,
                            autoplay: false,
                            pip: true,
                            autoMini: true,
                            screenshot: true,
                            setting: true,
                            loop: true,
                            flip: true,
                            playbackRate: true,
                            aspectRatio: true,
                            fullscreen: true,
                            subtitleOffset: true,
                            miniProgressBar: true,
                            mutex: true,
                            backdrop: true,
                            playsInline: true,
                            autoPlayback: true,
                            airplay: true,
                            autoOrientation: true,
                            lock: true,
                            quality: fetchVideo.map((item, index) => ({
                                default: index === qualityIndex,
                                html: ` ${item.label}`,
                                url: item.file.replace(/^file:/, ""),  // Remove 'file:' prefix
                            })),
                            subtitle: {
                              url: firstSubtitleUrl,
                              type: 'vtt',
                              style: {
                                  color: '#ADD8E6',
                                  fontSize: '20px',
                              },
                              encoding: 'utf-8',
                          },
                        }}
                    />
                </div>
            ) : (
                <div className="artplayerframe"></div>
            )}
        </div>
    );
}

export default MovStream;
